import React, { useState } from 'react';
import SpecialCard from '../SpecialCard/SpecialCard';
import './special-menu.scss';
import longDistance from "../../images/long-distance.png"
import areaAttack from "../../images/area-attack.png"
import shield from "../../images/shield.png"

function SpecialsMenu({
  attackSpecial0, special0, buySpecial, connected,
}) {
  const specials = [{
    id: 0,
    title: 'LONG DISTANCE ATTACK',
    description: 'This attack allows you to attack a territory 5 blocks away',
    image: longDistance,
    goldCost: 50,
    staminaCost: 50,
    owned: special0,
    available: true,
    /* use: attackSpecial0, */
  }, {
    id: 1,
    title: 'AREA ATTACK',
    image: areaAttack,
    description: `Use 450 units to attack 9 adjacent territories up to 5 blocks away`,
    goldCost: 500,
    staminaCost: 300,
    owned: 0,
    available: true
  },
  {
    id: 2,
    title: 'SUPER DEFENDER',
    image: shield,
    description: `Grant a 70% defend bonus to a territory that you own for a period of 12 hours`,
    goldCost: 20,
    staminaCost: 20,
    owned: 0,
    available: true
  }
 ];

  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={`specials-menu-wrapper ${isOpen ? 'open' : ''}`}>
      <div className="specials-menu-container custom-scrollbar">
        <div className="specials-menu-list">
          {specials.map((special) => (
            <div className="flex" key={special.id}>
              <SpecialCard
                key={special.title}
                title={special.title}
                image={special.image}
                description={special.description}
                goldCost={special.goldCost}
                staminaCost={special.staminaCost}
                owned={special.owned}
                available={special.available}
                attackSpecial0={attackSpecial0}
                buySpecial={buySpecial}
                connected={connected}
              />
              <div className="btns-container" key={special.id}>
                <button className="buy-btn" onClick={() => buySpecial(special.id, 1)}>BUY</button>
                <button className="use-btn" onClick={() => attackSpecial0(special.id)}>USE</button>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="specials-menu-toggle" onClick={() => setIsOpen(!isOpen)}>
        SPECIALS
        <span className={isOpen ? 'arrow-down' : 'arrow-up'} />
      </div>
    </div>
  );
}

export default SpecialsMenu;
