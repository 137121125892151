import diamondContractABI from "./utils/diamondABI.json";
import ERC20ABI from "./utils/IERC20ABI.json";
import SPECIAL_ABI from "./utils/specialABI.json";

const DIAMOND_CONTRACT_ADDRESS = "0xa405cD49056CB9A4f2460898d7562E4d8029Ae0b";
const STAMINA_CONTRACT_ADDRESS = "0x7B3CDa9D4dC3ae776252c34Df66e88475bEA8c9F";
const GOLD_CONTRACT_ADDRESS = "0x6431FE55dFe245B2fe61E31f91a1a807434157d9";
const SPECIAL_CONTRACT_ADDRESS = "0x56a366Adf44bC1f3AfC4b4570f466418FaBa339c";

export {
  DIAMOND_CONTRACT_ADDRESS,
  diamondContractABI,
  STAMINA_CONTRACT_ADDRESS,
  GOLD_CONTRACT_ADDRESS,
  ERC20ABI,
  SPECIAL_CONTRACT_ADDRESS,
  SPECIAL_ABI,
};
